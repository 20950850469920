<script setup lang="ts">
import { defineProps, watch, withDefaults } from 'vue';
import { GroupProgress } from 'o365.pwa.modules.client.steps.GroupProgress.ts';

interface IProps {
    syncStepProgress: GroupProgress,
    currentStep: boolean
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: false
});
</script>

<script lang="ts">
export default {
    name: 'GroupProgressOffline'
}
</script>

<template>
    <component v-for="(SyncStepProgress, index) in syncStepProgress.stepsProgress" :is="SyncStepProgress.vueComponentName"
        :syncStepProgress="SyncStepProgress" :currentStep="currentStep"/>
</template>
